import React from "react"
// import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import LeftMenu from "./leftMenu"
import Winners from '../../images/WINNERS-photo.jpg'

const MccartheyFamilyFoundationLectureSeries = () => (
  <Layout>
    <SEO title="DORIS KEARNS GOODWIN, PULITZER PRIZE-WINNING AUTHOR & PRESIDENTIAL HISTORIAN, TO SPEAK AT MCCARTHEY FAMILY FOUNDATION LECTURE SERIES" />

      <div className="subpageContainer">

        <LeftMenu active="home" />
        <div className="body">


          <h2 style={{textTransform: 'none', margin: '60px 0', padding: 0}}>McCarthey Family Foundation Lecture Series: In Praise of Independent Journalism</h2>
          <h1 style={{marginTop: '55px'}}>when the medium is the message</h1>

          <h2>guest lecturers</h2>
          <div className="guest-lectures">
          <div><span className="year">2006</span>Helen Thomas</div>
          <div><span className="year">2007</span>Arianna Huffington</div>
          <div><span className="year">2008</span>Anderson Cooper</div>
          <div><span className="year">2009</span>Daniel Schorr </div>
          <div><span className="year">2010</span>Ingrid Betancourt</div>
          <div><span className="year">2011</span>Chris Matthews</div>
          <div><span className="year">2012</span>Doris Kearns Goodwin</div>
          <div><span className="year">2013</span>Madeleine K. Albright</div>
          <div><span className="year">2014</span>David Axelrod</div>
          <div><span className="year">2015</span>Bob Costas</div>
          <div><span className="year">2016</span>Tom Brokaw</div>
          <div><span className="year">2017</span>David Remnick</div>
          <div><span className="year">2018</span>Mika Brzezinski & Joe Scarborough</div>
          <div><span className="year">2019</span>Jon Meacham</div>
          <div><span className="year">2020</span>Jane Mayer, Nov. 7, 2020</div>

        <div>
          <p><br /></p>
          <h2>Essay Competition</h2>
          <img src={Winners} alt="Winners" align="right" style={{display: 'inline-block', position: 'relative', margin: '0 0 10px 25px', border: '5px solid white', boxShadow: '0 0 15px rgba(0,0,0,.5)'}} />
          <p>Students at colleges and universities in Utah were invited to submit an original essay of 500 words in conjunction with the lecture. The essay competition was established in 2009 to encourage students to reflect upon independent journalism and the vital role it plays in our democracy. The winning essayist received a cash prize of $2500.00 the evening of the lecture.</p>
          <p>In 2018, the essay competition expanded to encourage even younger students to begin thinking about the vital role a free press plays in a democracy. High school and junior high school students statewide were invited to consider this issue in order to stimulate greater awareness and interest among our future citizens and leaders. </p>

          <p>
            The winning Utah college/university essay of 1,000 words received a cash prize of $4,000.00.<br />
            The winning Utah high school student essay (grades 9-12) of 750 words received a cash prize of $2,500.00.<br />
            The winning Utah junior high school student essay (grades 8-10) of 500 words received a cash prize of $1,500.00.<br />
          </p>
        </div>


        <div>
        <p><br /></p>
        <h2>the message</h2>
        <p>Honoring a commitment initiated by their great-grandfather United States Senator Thomas Kearns, who purchased The Salt Lake Tribune in 1901, and the request of their father, Thomas K. McCarthey, to cultivate a “lasting legacy for an independent press,” today’s trustees have established this public forum. In recognition of the vital role an independent press plays in a democracy, the series sponsors public lectures by and concerning journalists who have demonstrated the highest level of courageous, thoughtful and unbiased reporting.</p>
        </div>
        </div>
          </div>
          </div>
  </Layout>
)

export default MccartheyFamilyFoundationLectureSeries
